import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import Header from './components/Header';
import Footer from './components/Footer';
import NFTGallery from './components/NFTGallery';
import MintSection from './components/MintSection';
import UpgradeSection from './components/UpgradeSection';
import DoubleMintSection from './components/DoubleMintSection';
import TotalMints from './components/TotalMints';
import UpgradeFees from './components/UpgradeFees';
import ETCPOWBurned from './components/ETCPOWBurned'; // Newly imported component
import './styles.css';
import FIRE3 from './images/FIRE3.png';
import FIRE4 from './images/FIRE4.png';
import DAO3_ABI from './contracts/ETCMC-DAO3-ABI.json';

const dao3Address = "0x911211d3ebB4C61423d11a3bB6484d05333C576b";

const App = () => {
  const [walletConnected, setWalletConnected] = useState(false);
  const [signer, setSigner] = useState(null);
  const [contract, setContract] = useState(null);

  useEffect(() => {
    const initializeContract = async () => {
      if (walletConnected && signer) {
        try {
          const contractInstance = new ethers.Contract(dao3Address, DAO3_ABI, signer);
          setContract(contractInstance);
        } catch (error) {
          console.error('Contract initialization failed:', error);
        }
      }
    };

    initializeContract();
  }, [walletConnected, signer]);

  return (
    <div className="App">
      <Header
        setWalletConnected={setWalletConnected}
        setSigner={setSigner}
        setContract={setContract}
        signer={signer}
      />
      <div className="app-title">
        <h1>ETCMC DAO3-FURNACE</h1>
      </div>
      {walletConnected ? (
        <div className="container">
          <NFTGallery signer={signer} />
          <div className="action-buttons">
            <UpgradeSection signer={signer} />
            <MintSection signer={signer} contract={contract} />
            <DoubleMintSection signer={signer} />
          </div>
          <TotalMints contract={contract} />
          <UpgradeFees contract={contract} />
          <ETCPOWBurned provider={signer.provider} /> {/* Pass the provider from signer */}
        </div>
      ) : (
        <p>Please connect your wallet to view and interact with NFTs.</p>
      )}
      <Footer />
      <img src={FIRE3} alt="Fire" className="fire-image" />
      <img src={FIRE4} alt="Flipped Fire" className="flipped-fire-image" />
    </div>
  );
};

export default App;

























