import React, { useEffect, useState } from 'react';
import { Contract, formatEther } from 'ethers'; // Import Contract and formatEther from ethers
import ETCPOW_ABI from '../contracts/ETCPOW-ABI.json'; // Adjust the path to your ABI file

const ETCPOWBurned = ({ provider }) => {
  const [etcpowBurned, setEtcpowBurned] = useState(null);
  const burnAddress = '0x000000000000000000000000000000000000dEaD';
  const etcpowAddress = '0x6c3B413C461c42a88160Ed1B1B31d6f7b02a1C83';
  const dao3Address = '0x911211d3ebB4C61423d11a3bB6484d05333C576b'; // DAO3 contract address

  useEffect(() => {
    const fetchETCPOWBurnedFromDAO3 = async () => {
      try {
        const etcpowContract = new Contract(etcpowAddress, ETCPOW_ABI, provider);

        // Fetch all transfer events to the burn address
        const filter = etcpowContract.filters.Transfer(null, burnAddress);
        const events = await etcpowContract.queryFilter(filter);

        console.log('Fetched Transfer events to burn address:', events);

        const dao3BurnEvents = [];

        for (const event of events) {
          const txReceipt = await provider.getTransactionReceipt(event.transactionHash);
          
          // Log each transaction receipt to see the details
          console.log(`Transaction Receipt for hash ${event.transactionHash}:`, txReceipt);
          
          // Check if any of the logs contain the DAO3 contract address
          const dao3Interaction = txReceipt.logs.some(log => log.address === dao3Address);
          
          if (dao3Interaction) {
            console.log(`Found DAO3 interaction in tx ${event.transactionHash}`);
            dao3BurnEvents.push(event); // Only add events that interacted with the DAO3 contract
          } else {
            console.log(`No DAO3 interaction in tx ${event.transactionHash}`);
          }
        }

        console.log('Filtered DAO3-related events:', dao3BurnEvents);

        // Sum the amount of ETCPOW sent to the burn address by DAO3-related transactions
        const totalBurned = dao3BurnEvents.reduce((acc, event) => acc + event.args.value, 0n); // Using BigInt for large values

        console.log('Total burned (wei):', totalBurned.toString());

        // Convert the totalBurned from wei to ETCPOW and set state
        setEtcpowBurned(formatEther(totalBurned));
      } catch (error) {
        console.error('Failed to fetch ETCPOW burned from DAO3:', error);
      }
    };

    fetchETCPOWBurnedFromDAO3();
  }, [provider]);

  if (etcpowBurned === null) return <p>Loading ETCPOW burned...</p>;

  return (
    <div>
      <p>ETCPOW Burned by the Furnace: {etcpowBurned} ETCPOW</p>
    </div>
  );
};

export default ETCPOWBurned;













